@import "~antd/dist/antd.less";
@import "~antd/dist/antd.compact.less"; // Introduce the official compact less style entry file

.App {
  margin-top: 16px;
  text-align: center;
}

.ButtonControls {
  margin-bottom: 20px;
}

.TableResults {
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}

.LineupCard {
  margin-bottom: 35px;
}

.LineupFeedback {
  margin-bottom: 20px;
}

.ControlButton {
  margin-right: 4px;
}

.SettingsContainer {
  width: 100%;
  margin-top: 35px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

.FilterText {
  margin-top: 24px;
}

.SettingsContainer {
  margin-left: 15%;
  margin-bottom: 4px;
}

.MiniControlContainer {
  margin-top: 4px;
  padding: 16px;
  text-align: left;
  width: 100%;
}

.MiniControlTitle {
  font-size: 1rem;
  margin-bottom: 0;
}

.AggregateContainer {
  margin-bottom: 16px;
}

.CustomProj {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 85%;
}

@compact: true;